<!--/* eslint-disable vue/no-v-html */-->
<template>
  <div class="domain-detail-whois">
    <page-block :title="`${$t('title')} ${tariff.pricelist} #${tariff.id} ${tariff.period}`">
      <main-card>
        <div ref="whois" class="domain-detail-whois__content standart-text" />
      </main-card>
    </page-block>
  </div>
</template>

<script>
import PageBlock from '@/components/PageBlock/PageBlock.vue';
import MainCard from '@/components/MainCard/MainCard';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import { IdprotectTariff } from '@/models/BillMgr/IdprotectTariff';
export default {
  name: 'Instruction',
  components: { PageBlock, MainCard },
  mixins: [billMgrToolActions],
  props: {
    tariff: {
      type: IdprotectTariff,
      required: true,
      validator: obj => obj instanceof IdprotectTariff,
    },
  },
  computed: {
    tools() {
      return this.$store.state.moduleIdprotect.tools;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(id = this.tariff.id) {
      const params = {
        elid: id,
        func: 'service.instruction.html',
      };
      this.fetchBillMgrAction(params).then(data => {
        return this.fillWhois(data.model.body);
      });
    },
    fillWhois(str) {
      this.$refs.whois.innerHTML = str.trim();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Инструкция"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domain-detail-whois {
  &__content {
  }
}
</style>
