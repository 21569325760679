export default {
  computed: {
    list() {
      return this.$store.state.moduleIdprotect.list;
    },
    isLoading() {
      return this.$store.state.moduleIdprotect.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleIdprotect/fetchList');
    },
  },
};
